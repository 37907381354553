.print-button {
  display: flex !important;
  margin-left: auto !important;
  margin-bottom: 10px !important;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  background: white !important;
  border-bottom: 1px solid #ccc7c7;
  opacity: 0.8;
}

h3,
h4,
h6 {
  margin-bottom: 0;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
    h6 {
      font-size: 1rem;
    }

    h4 {
      font-size:2rem;
    }
}

.col:last-child {
  text-align: right;
}


@media screen and (max-width: 768px) {
    .report-header {
      flex-flow: column;
      text-align: center;
    }

    .report-header h4 {
      font-size: 2rem;
    }

    .report-header h6 {
      font-size: 1rem;
    }

    .report-header .col:last-child {
      margin-top: 20px;
    }
}


/* report header ends here */

.report-card {
}

.card-header {
    margin: 20px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.card-header h4 {
    display: flex;
    align-items: center;
}

.card-header h4 .icon {
    margin-left: 10px;
    cursor: pointer;
    color: #FF1744;
}

.card-header-inner {
  text-align: center;
  margin: auto;
}

.card-header-inner .icon-container {
  border: 1px solid #ccc7c7;
  border-radius: 100%;
  padding: 10px;
}
.card-header-inner .icon {
  color: #6e6e6e;
  width: 20px;
  height: 20px;
  display: block;
}


@media screen and (max-width: 768px) {
  .card-header {
    flex-flow: column;
  }

  .card-header h4{
    font-size: 2rem;
  }

 
}


/* card header inner styles ends here */

.card-title .alt span:last-child {
  font-size: 14px;
}

/* card title styles ends here */

.card-content {
  margin-bottom: 20px;
}

.card-content:last-child {
  margin-bottom: 0;
}

.card-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #ccc7c7;

  /* &.alt {
      background: #e6e8ed;
    } */
}

.card-alt {
  display: block;
}

.card-row {
    padding: 10px 0;
}

.card-row .card-row-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #ccc7c7;
  margin-left: 40px;
}

.card-row h6 {
  margin-bottom: 0;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #6e6e6e;
}

.card-row .profit-title {
  text-transform: uppercase;
  color: #6e6e6e;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0;
}



/* additional styles */

/* ReceiptStyles.css */

.main-receipt {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0;
  padding: 0;
  border: 1px solid #cccccc;
  max-width: 700px;
  width: 80%;
  margin: auto;
}

.header {
  display: flex;
  background-color: #212529;
  color: white;
  padding: 8px 0;
  text-align: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

img {
  width: 40%;
  height: 20%;
}

.logo {
  width: 200px;
  vertical-align: middle;
  object-fit: cover;
  height: 60px;
  margin: auto;
}

.success-section {
  padding: 20px 0;
  text-align: center;
}

.icon-container {
  display: flex;
  justify-content: center;
}

.success-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 1px solid #212529;
  border-radius: 100%;
  padding: 2px;
  font-size: 20px;
  width: 20px;
  height: 20px;
}

.heading {
  color: #212529;
  font-size: 24px;
  font-family: "Albert Sans", sans-serif;
  margin: 10px 0 20px 0;
}

.info-text {
  width: 100%;
  text-align: center;
  color: #757575;
  font-family: "Albert Sans", sans-serif;
  font-size: 14px;
}

.order-number {
  margin: 0;
  font-weight: 700;
}

.order-info-header {
  background-color: #212529;
  padding: 5px 0;
}

.order-info-heading {
  text-align: center;
  color: white;
  font-family: "Albert Sans", sans-serif;
  font-size: 20px;
  margin: 8px 0;
}

.order-info-content {
  border-bottom: 1px solid #CCCCCC;
  padding: 20px 0;
}

.order-info-table, 
.order-summary-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.order-info-table col {
  width: 40%;
}

.order-info-table th,
.order-info-table td,
.order-summary-table th,
.order-summary-table td {
  text-align: left;
  /* margin: 10px 0; */
  padding: 0 10px;
  font-weight: 600;
  height: 40px;
  font-family: "Albert Sans", sans-serif;
  font-size: 14px;
}

.order-info-table th .info-text,
.order-summary-table th .info-text {
  color: #212529;
}

.order-summary {
  padding: 20px 0;
}


.subtotal,
.total {
  font-size: 14px;
  font-weight: 700;
}
