.step-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-button-wrapper {
  display: flex;
  align-items: center;
}

Button {
  font-size: 16px;
  padding: 10px 20px;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: #ccc7c7;
  color: #fff;
}

Button.completed {
  background-color: #33b27b !important; /* Green color for completed steps */
  color: #fff !important;
}

.separator {
  height: 2px;
  width: 30px;
  background-color: #ccc7c7;
  /* margin: 0 10px; */
}

.separator.completed {
  background-color: #33b27b; /* Green color for completed separators */
}

.completed {
  background-color: green; /* Set your completed color here */
}

.current {
  background-color: blue; /* Set your current color here */
}

@media screen and (max-width: 768px) {
  .step-buttons-container {
    margin-top: 20px;
  }

  Button {
    padding: 2px 5px !important;
    font-size: 10px !important;
  }
}
