.ListItemText {
    position: relative;
    padding-left: 20px; /* Adjust the space between bullet and text */
  }
  
  .ListItemText:before {
    content: '\2022'; /* Unicode character for bullet */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  