.paginationMenu {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.pageLink {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.menuItem:first-child .pageLink {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.menuItem.disabled .pageLink {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

/* modal styles */

.modal-content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate3d(0, -50%, -50%) !important;
  max-width: 400px !important;
  width: 100% !important;
  background: white !important;
  border: 0 !important;
  outline: none !important;
}

@media (max-width: 1024px) {
  /* Adjust styles for mobile devices */
  .modal-content {
    left: 0 !important;
    transform: translate3d(0, -50%, 0) !important;
    width: 100% !important;
    max-width: none !important;
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.modal-icon {
  cursor: pointer;
}

.popup {
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  /* padding: 10px 0; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.popup .content {
  border-bottom: 1px solid #ccc !important;
  color: #333;
  padding: 10px;
}

.popup .content:last-child {
  border: 0 !important;
}
